export const parseContentProps = (content) => {
  const props = {};

  if (content.value) {
    props.value = content.value;
  }

  if (content.marks && content.marks.length) {
    props.marks = content.marks;
  }

  return props;
};

export const parseContent = (document) => {
  const parsed = {
    type: document.nodeType,
    props: parseContentProps(document),
  };

  if (document.data) {
    parsed.data = document.data;
  }

  if (document.content) {
    parsed.content = document.content.map(parseContent);
  }

  return parsed;
};

export const parseDocument = (document) => {
  if (!document) {
    throw new Error('Failed to parse invalid document');
  }
  return parseContent(document);
};

export function formatDate(date, options = {}) {
  if (!date) return '';

  const formattedDate = new Date(date);

  if (isNaN(formattedDate.getTime())) {
    throw new TypeError('Provided date is invalid');
  }

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  if (options.useUTCDates) {
    dateOptions.timeZone = 'UTC';
  }

  return formattedDate.toLocaleDateString(undefined, dateOptions);
}

export const parseMessage = (message) => {
  if (!message) {
    return;
  }

  const formattedMessage = Object.entries(message).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = value.map((d) => {
        if (isDocument(d)) {
          return parseDocument(d);
        } else {
          return d;
        }
      });
    } else if (isDocument(value)) {
      acc[key] = parseDocument(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  return formattedMessage;
};

export const isDocument = (thing) => {
  return Boolean(typeof thing === 'object' && thing.nodeType);
};

export const parseNotifications = (notifications) => {
  const parseNotification = (notification) => {
    const { fields } = notification;
    const { id, type, dismissBehavior, title, variant, cta, href } = fields;

    const sys = flattenSysObject(notification.sys);
    const icon = flattenIconObject(fields?.icon);
    return {
      ...sys,
      props: {
        id,
        type,
        message: parseMessage(fields?.message),
        legacyPage: parseLegacyPage(fields?.legacyPage),
        dismissBehavior,
        title,
        variant,
        icon,
        href,
        cta,
      },
    };
  };

  return notifications.map(parseNotification);
};

export const parseLegacyPage = (legacyPage) => {
  if (legacyPage) {
    return { props: { slug: legacyPage?.fields?.slug } };
  }

  return undefined;
};

export const flattenIconObject = (icon) => ({
  type: 'icon',
  id: icon.sys.id,
  createdAt: formatDate(icon.sys.createdAt),
  updatedAt: formatDate(icon.sys.updatedAt),
  revision: icon.sys.revision,
  props: icon.fields,
});

export const flattenSysObject = (sys) => ({
  type: 'notification',
  id: sys.id,
  createdAt: formatDate(sys.createdAt),
  updatedAt: formatDate(sys.updatedAt),
  revision: sys.revision,
});

export const loginLogoutItems = [
  {
    fields: {
      identifier: 'login',
      title: 'Login / Sign up',
      slug: '/profile/login',
      variant: 'link',
      targetUsers: 'logged-out',
      hasSeparator: true,
      items: [],
    },
  },
  {
    fields: {
      identifier: 'logout',
      title: 'Logout',
      slug: '/profile/logout',
      variant: 'link',
      targetUsers: 'logged-in',
      hasSeparator: true,
      items: [],
    },
  },
];

export const mapContentfulProfileNavigation = (profileGroups = [], isLoggedIn = false) => {
  const profileItems = [];
  const profileOptions = [...profileGroups, ...loginLogoutItems];
  profileOptions
    .filter(({ fields }) => {
      const groupsForUser = ['all'];
      if (isLoggedIn) {
        groupsForUser.push('logged-in');
      } else {
        groupsForUser.push('logged-out');
      }
      if (typeof fields.targetUsers === 'string') {
        return groupsForUser.includes(fields.targetUsers);
      }
    })
    .forEach((menuItem) => {
      profileItems.push({
        label: menuItem.fields.title,
        variant: menuItem.fields.variant ?? 'heading',
        url: menuItem.fields.slug,
        hasSeparator: menuItem.fields.hasSeparator,
      });
      menuItem.fields.items.forEach((submenuItem) => {
        profileItems.push({
          label: submenuItem.fields.title,
          url: submenuItem.fields.slug,
          variant: 'padded-link',
        });
      });
    });
  return profileItems;
};
