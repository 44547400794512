/* eslint-disable no-undef */
/* eslint-disable complexity */
const INTERNAL_DOMAINS = ['devinvh.com', 'qainvh.com', 'uatinvh.com', 'invitationhomes.com'];

export function hrefTarget(href) {
  if (href && typeof href === 'string') {
    return ['#', '/'].includes(href[0]) ||
      !href.split('').includes('.') ||
      INTERNAL_DOMAINS?.find((domain) => href.includes(domain))
      ? '_self'
      : '_blank';
  }
  return '_self';
}

export function hrefUrl(slug) {
  const protocols = ['http', 'https'];
  const url = protocols.find((protocol) => slug.includes(protocol));
  if (url) {
    return slug;
  }
  if (window) {
    const fullDomain = window.location.hostname.split('.');
    const protocol = window.location.protocol;
    const domain = fullDomain.slice(-2).join('.');
    return new URL(slug, `${protocol}//www.${domain}`).toString();
  }
  return;
}
