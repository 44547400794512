const axios = require("axios");

export default class PLSClient {
  state = { markets: false };

  promises = { markets: false };

  constructor(options = {}) {
    if (!options.endpoint) {
      throw new Error("PLS-Client: options.endpoint is required");
    }

    this.endpoint = options.endpoint;
    this.connector = options.connector || axios.create();
  }

  async getMarkets(config = {}, force = false) {
    if (force || !this.promises.markets) {
      this.promises.markets = this.connector
        .request({
          ...config,
          method: "get",
          url: `${this.endpoint}/markets`,
        })
        .then((data) =>
          data.data.results.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
        );
    }

    const markets = await this.promises.markets;
    this.state.markets = markets;
    return markets;
  }

  getLayoutData() {
    return this.connector.get(`${this.endpoint}/layout.json`);
  }

  getUnit(unitData) {
    return this.connector
      .get(`${this.endpoint}/property-by`, { params: unitData })
      .then((response) => response.data);
  }

  getUnits(unitData) {
    return this.connector
      .post(`${this.endpoint}/properties-by`, unitData)
      .then((response) => response.data);
  }

  async getUnitSmarthome(unit_id, options = {}) {
    const unitData = {
      unit_id,
      ...options,
    };

    const unit = await this.connector
      .get(`${this.endpoint}/property-by`, { params: unitData })
      .then((response) => response.data);

    if (
      unit.allow_selfshow &&
      !unit.has_trouble_condition &&
      unit.unit_status === "Vacant Unrented Ready"
    ) {
      return unit;
    }
    return false;
  }
}
